export default component => {
    const themes = ['greenmatch', 'solarguide', 'silver-blaze', 'boscombe-valley'];

    const blockName = component.blockName;
    const isValid = component.isValid ?? true;

    if (!blockName || !isValid) return null;

    const type = blockName.split('/')[0];
    let name = blockName.split('/')[1].replace('macro-', '');

    themes.forEach(theme => {
        if (name.includes(theme)) {
            name = name.replace(`${theme}-`, '');
        }
    });

    if (type === 'core') return `wordpress-${name}`;
    if (type === 'tablepress') return `tablepress-${name}`;

    return `watson-${name}`;
};
